import React, { memo, useEffect, useState } from "react";
import OrderScreen from "../OrderScreen/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  getChefsList,
  getDeliveryAgents,
  getKitchensList,
  getOrders,
  updateDeliveryAgents,
  updateKitchen,
} from "../../redux/actions/OrderAction";
import SignUp from "../SignUp/Index";
import { toast } from "react-toastify";
import { onMessageListener } from "../../firebase";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import InstallApp from "../InstallApp/Index";
import ChefDishesDrawer from "../OrderScreen/ChefDishesDrawer";
import DineInBooking from "../DineInBooking/Index";

const MobileView = memo(({ sendNotification, isDesktopView, token, setParentCurrentUserType }) => {

  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("orderId");

  const dispatch = useDispatch();
  const [screenState, setScreenState] = useState(
    +localStorage.getItem("userType") ? 3 : 2
  );
  const [notification, setNotification] = useState(null);
  const channel = new BroadcastChannel('background-messages');
  const [currentUserType, setCurrentUserType] = useState(
    +localStorage.getItem("userType") || 0
  );
  const orderReducer = useSelector((state) => state.orders.orders);
  const updateorderReducer = useSelector((state) => state.orders.updateOrders);
  const kitchensListList = useSelector(
    (state) => state.orders.kitchensList.data
  );
  const deliveryAgentList = useSelector(
    (state) => state.orders.deliveryAgent.data
  );
  const { data: orderDataList = [] } = orderReducer;


  useEffect(() => {
    if (screenState === 2) {
      localStorage.removeItem("userId");
      localStorage.removeItem("userType");
    }
  }, [screenState]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOrderClick = (orderDetails) => {
    handleScrollToTop();
    setScreenState(4);
  };

  useEffect(() => {
    const { rowData = [] } = orderReducer || {};
    if (
      orderId &&
      screenState !== 2 &&
      orderDataList.length &&
      rowData.length
    ) {
      const currentOrder = rowData.find((a) => a._id === orderId);
      if (currentOrder) {
        handleOrderClick({ ...orderDataList[0].dayOrder[0] });
      }
    }
  }, [orderId, orderDataList]);

  useEffect(() => {
    if (updateorderReducer?.success && updateorderReducer.data) {
      getOrdersAction();
    }
  }, [updateorderReducer]);

  const getOrdersAction = () => {
    if (!userId) return;
    if (currentUserType === 1) {
      dispatch(getOrders({ driverId: userId, userType: "Kitchen" }));
    } else if (currentUserType === 2) {
      dispatch(getOrders({ driverId: userId, userType: "deliveryBoy" }));
    }
  };

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    getOrdersAction();
  }, [currentUserType, userId]);

  useEffect(() => {
    dispatch(getDeliveryAgents());
    dispatch(getKitchensList());
    dispatch(getChefsList());
  }, []);

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    const userId = localStorage.getItem("userId");
    if (userType && userId) {
      if (+userType === 1) {
        if (token) {
          const currentUser = [...kitchensListList].find(
            (a) => a._id === userId
          );
          if (!!currentUser && currentUser?.firebaseToken !== token) {
            dispatch(
              updateKitchen({ id: userId, firebaseToken: token })
            );
            toast.success("Token saved");
          }
        }

      } else {
        if (token) {
          const currentUser = [...deliveryAgentList].find(
            (a) => a._id === userId
          );
          if (!!currentUser && currentUser?.firebaseToken !== token) {
            dispatch(
              updateDeliveryAgents({
                id: userId,
                firebaseToken: token,
              })
            );
            toast.success("Token saved");
          }
        }
      }
    }
  }, [kitchensListList, token, deliveryAgentList]);

  onMessageListener()
    .then((payload) => {
      toast.success("New Order Received");
      const notificationSoundPlay = new Audio("https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/notification.mp3");
      notificationSoundPlay.play();
      getOrdersAction();
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    channel.onmessage = handleMessage;

    return () => {
      channel.close();
    };
  }, []);

  const handleMessage = (event) => {
    console.log('Received message from service worker:', event.data);
    toast.success("New Order Received");
    const notificationSoundPlay = new Audio("https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/notification.mp3");
    notificationSoundPlay.play();
    setNotification(event.data);
    getOrdersAction();
  };

  function About() {
    return (
      <div>
        <h2>About</h2>
      </div>
    );
  }

  function Home() {
    return (
      <div>
        {[1, 2].includes(screenState) && (
          <SignUp
            token={token}
            isLogin={screenState === 2}
            setCurrentUserType={setCurrentUserType}
            setParentCurrentUserType={setParentCurrentUserType}
            setScreenState={setScreenState}
          />
        )}
        {[1, 2].includes(currentUserType) && (
          <>
            <OrderScreen
              isDesktopView={isDesktopView}
              setScreenState={setScreenState}
              handleOrderClick={handleOrderClick}
              getOrdersAction={getOrdersAction}
              sendNotification={sendNotification}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chefdishes" element={<ChefDishesDrawer />} />
        <Route path="/dineinbooking" element={<DineInBooking />} />
      </Routes>
    </Router>
      <InstallApp />
    </div>
  );
});

export default MobileView;
