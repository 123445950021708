import { all, call, put, takeLatest } from "redux-saga/effects";
import Cookies from "js-cookie";

import { Axios } from "../../api/axios";
import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  UPDATE_ORDERS_REQUEST,
  UPDATE_ORDERS_SUCCESS,
  UPDATE_ORDERS_ERROR,
  GET_DELIVERY_AGENT_REQUEST,
  GET_DELIVERY_AGENT_SUCCESS,
  GET_DELIVERY_AGENT_ERROR,
  UPDATE_DELIVERY_AGENT_REQUEST,
  GET_KITCHENS_LIST_REQUEST,
  GET_KITCHENS_LIST_SUCCESS,
  GET_KITCHENS_LIST_ERROR,
  UPDATE_KITCHEN_REQUEST,
  UPDATE_KITCHEN_SUCCESS,
  UPDATE_KITCHEN_ERROR,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  ADD_DRIVER_REQUEST,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_ERROR,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  POST_LIVE_LOCATION_REQUEST,
  POST_LIVE_LOCATION_SUCCESS,
  POST_LIVE_LOCATION_ERROR,
  GET_CHEFS_LIST_REQUEST,
  GET_CHEFS_LIST_ERROR,
  GET_CHEFS_LIST_SUCCESS,
  UPDATE_CHEFS_DETAIL_REQUEST,
  UPDATE_CHEFS_DETAIL_SUCCESS,
  UPDATE_CHEFS_DETAIL_ERROR,
  GET_CHEF_MENU_REQUEST,
  GET_CHEF_MENU_SUCCESS,
  GET_CHEF_MENU_ERROR,
  UPDATE_DISH_REQUEST,
  UPDATE_DISH_SUCCESS,
  UPDATE_DISH_ERROR,
  ADD_KITCHEN_REQUEST,
  ADD_KITCHEN_SUCCESS,
  ADD_KITCHEN_ERROR,
  ADD_CHEF_REQUEST,
  ADD_CHEF_SUCCESS,
  ADD_CHEF_ERROR,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  GET_DINEIN_BOOKING_REQUEST,
  GET_DINEIN_BOOKING_SUCCESS,
  GET_DINEIN_BOOKING_ERROR,
} from "../reducers/OrderReducer";
import { getSimplifiedError } from "../../utils/error";
import { toast } from "react-toastify";

async function getOrders(payload) {
  const { driverId, userType } = payload || {};
  if (userType === "deliveryBoy") {
    return await Axios.get(`/activeDeliveryOrders/${driverId}`, payload);
  }
  return await Axios.get("/activeOrders", payload);
}

function* handleGetOrders({ payload }) {
  try {
    const response = yield call(getOrders, payload);
    if (response) {
      yield put({
        type: GET_ORDERS_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ORDERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}


async function sendOtp(payload) {
  return await Axios.get("https://api.msg91.com/api/v5/otp", { params: payload });
}

function* handleSendOtp({ payload }) {
  try {
    const response = yield call(sendOtp, payload);
    if (response) {
      yield put({
        type: SEND_OTP_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: SEND_OTP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}


async function addDriverApi(payload) {
  return await Axios.post("/addDriver", payload);
}

function* handleAddDriver({ payload }) {
  try {
    const response = yield call(addDriverApi, payload);
    if (response) {
      toast.success("Registration Successful")
      yield put({
        type: ADD_DRIVER_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_DRIVER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}


async function addKitchenApi(payload) {
  return await Axios.post("/addKitchen", payload);
}

function* handleAddKitchen({ payload }) {
  try {
    const response = yield call(addKitchenApi, payload);
    if (response) {
      toast.success("Registration Successful")
      yield put({
        type: ADD_KITCHEN_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_KITCHEN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addChefApi(payload) {
  return await Axios.post("/addChef", payload);
}

function* handleAddChef({ payload }) {
  try {
    const response = yield call(addChefApi, payload);
    if (response) {
      toast.success("Chef registration successful")
      yield put({
        type: ADD_CHEF_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_CHEF_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addCategoryApi(payload) {
  const { type, chefId, ...rest } = payload;
  if(type === "CREATE"){
    return await Axios.post("/menu", {...rest, chefId});
  }else{
    return await Axios.post(`/menu/${chefId}`, rest);
  }
}

function* handleAddCategory({ payload }) {
  try {
    const response = yield call(addCategoryApi, payload);
    if (response) {
      toast.success("Chef menu registration successful")
      yield put({
        type: ADD_CATEGORY_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateOrder(payload) {
  const { id, ...rest } = payload;
  return await Axios.post(`/order/assignDeliveryAgentInfo/${id}`, rest);
}

function* handleUpdateOrder({ payload }) {
  try {
    const response = yield call(updateOrder, payload);
    if (response) {
      yield put({
        type: UPDATE_ORDERS_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_ORDERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateKitchen(payload) {
  const { id, ...rest } = payload;
  return await Axios.post(`/updateKitchen/${id}`, rest);
}

function* handleUpdateKitchen({ payload }) {
  try {
    const response = yield call(updateKitchen, payload);
    if (response) {
      yield put({
        type: UPDATE_KITCHEN_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_KITCHEN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getUserInfo(payload) {
  const { userId } = payload;
  return await Axios.get(`/user/${userId}`);
}

function* handleGetUserInfo({ payload }) {
  try {
    const response = yield call(getUserInfo, payload);
    if (response) {
      yield put({
        type: GET_USER_INFO_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_INFO_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getDeliveryAgent(payload) {
  return await Axios.get(`/user/getDrivers`);
}

function* handleGetDeliveryAgent({ payload }) {
  try {
    const response = yield call(getDeliveryAgent, payload);
    if (response) {
      yield put({
        type: GET_DELIVERY_AGENT_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_DELIVERY_AGENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getKitchensList(payload) {
  return await Axios.get(`/user/getKitchens`);
}

function* handleGetKitchensList({ payload }) {
  try {
    const response = yield call(getKitchensList, payload);
    if (response) {
      yield put({
        type: GET_KITCHENS_LIST_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_KITCHENS_LIST_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

const getWeekNumberInMonth = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const weekNumber = Math.ceil((date.getDate() + firstDayOfMonth.getDay()) / 7);
  return weekNumber;
}

async function getGetChecMenuList(payload) {
  return await Axios.get(`menu/chef/categories/${payload.chefId}`);
}

function* handleGetChecMenuList({ payload }) {
  try {
    const response = yield call(getGetChecMenuList, payload);
    if (response) {
      const currentDate = new Date();
    const currentWeekdayNumber = currentDate.getDay();
      const categoriesList = response.data.categories.map((item) => {
        const allDishes = [...item.dishes]
        if (item?.type == "daily") {
          const currentDishes = allDishes.find((a) => a.weekDay === currentWeekdayNumber);
          item.dishes = currentDishes?.dishes || []
        } else if (item?.type == "weekly") {
          const currentDate = new Date();
          const weekNumberInMonth = getWeekNumberInMonth(currentDate);
          const currentDishes = allDishes.find((a) => a.weekNumber === weekNumberInMonth);
          item.dishes = currentDishes?.dishes || []
        }
        item.menuId = response.data._id
        return item;
      });
      const filterCategoriesList = categoriesList.filter((a) => a.dishes.length);
      const allCategoriesList = categoriesList;
      yield put({
        type: GET_CHEF_MENU_SUCCESS,
        response : { categories: filterCategoriesList, allCategoriesList, chefId: payload.chefId  },
      });
    }
  } catch (error) {
    yield put({
      type: GET_CHEF_MENU_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateDishApi(payload) {
  const {dishValue, chefId , categoryId , dishId } = payload;
  if(!dishId){
    return await Axios.post(`/addChefDish/${chefId}/${categoryId}`, dishValue);
  }
  return await Axios.post(`/chefDish/${chefId}/${categoryId}/${dishId}`, dishValue);
}

function* handleUpdateDish({ payload }) {
  try {
    const response = yield call(updateDishApi, payload);
    if (response) {
      yield put({
        type: UPDATE_DISH_SUCCESS,
        response : {...response, dishId: payload.dishId, chefId: payload.chefId },
      });
      toast.success("Updated dish info successfully")
    }
  } catch (error) {
    yield put({
      type: UPDATE_DISH_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getChefsList(payload) {
  return await Axios.get(`/chef`);
}

function* handleGetChefsList({ payload }) {
  try {
    const response = yield call(getChefsList, payload);
    if (response) {
      yield put({
        type: GET_CHEFS_LIST_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CHEFS_LIST_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateChefsDetails(payload) {
  const { chefId, ...rest } = payload
  return await Axios.put(`/chefSwitchOff/${chefId}`, rest);
}

function* handleUpdateChefsDetails({ payload }) {
  try {
    const response = yield call(updateChefsDetails, payload);
    if (response) {
      yield put({
        type: UPDATE_CHEFS_DETAIL_SUCCESS,
        response,
      });
      if ("isSwitchOff" in payload) {
        toast.success(`Chefs status updated to ${payload.isSwitchOff ? `Offline` : `Online`}`);
      } else if ("prepTime" in payload) {
        toast.success(`Chefs time updated to ${payload.prepTime} mins`);
      }
    }
  } catch (error) {
    yield put({
      type: UPDATE_CHEFS_DETAIL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateDeliveryAgent(payload) {
  const { id, ...rest } = payload;
  return await Axios.post(`/updateDriver/${id}`, rest);
}

function* handleUpdateDeliveryAgent({ payload }) {
  try {
    const response = yield call(updateDeliveryAgent, payload);
    if (response) {
      yield put({
        type: GET_DELIVERY_AGENT_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_DELIVERY_AGENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function postLiveLocation(payload) {
  return await Axios.post(`addDeliveryCoordinates`, payload);
}

function* handlePostLiveLocation({ payload }) {
  try {
    const response = yield call(postLiveLocation, payload);
    if (response) {
      yield put({
        type: POST_LIVE_LOCATION_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: POST_LIVE_LOCATION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getDineinBooking(kitchenId) {
  return await Axios.get(`reservation/kitchen/${kitchenId}`);
}

function* handleGetDineinBooking({ payload }) {
  try {
    const response = yield call(getDineinBooking, payload.kitchenId);
    if (response) {
      yield put({
        type: GET_DINEIN_BOOKING_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_DINEIN_BOOKING_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_ORDERS_REQUEST, handleGetOrders),
  takeLatest(SEND_OTP_REQUEST, handleSendOtp),
  takeLatest(ADD_DRIVER_REQUEST, handleAddDriver),
  takeLatest(ADD_KITCHEN_REQUEST, handleAddKitchen),
  takeLatest(ADD_CHEF_REQUEST, handleAddChef),
  takeLatest(ADD_CATEGORY_REQUEST, handleAddCategory),
  takeLatest(UPDATE_ORDERS_REQUEST, handleUpdateOrder),
  takeLatest(GET_USER_INFO_REQUEST, handleGetUserInfo),
  takeLatest(GET_DELIVERY_AGENT_REQUEST, handleGetDeliveryAgent),
  takeLatest(GET_KITCHENS_LIST_REQUEST, handleGetKitchensList),
  takeLatest(GET_CHEF_MENU_REQUEST, handleGetChecMenuList),
  takeLatest(UPDATE_DISH_REQUEST, handleUpdateDish),
  takeLatest(GET_CHEFS_LIST_REQUEST, handleGetChefsList),
  takeLatest(UPDATE_CHEFS_DETAIL_REQUEST, handleUpdateChefsDetails),
  takeLatest(UPDATE_KITCHEN_REQUEST, handleUpdateKitchen),
  takeLatest(UPDATE_DELIVERY_AGENT_REQUEST, handleUpdateDeliveryAgent),
  takeLatest(POST_LIVE_LOCATION_REQUEST, handlePostLiveLocation),
  takeLatest(GET_DINEIN_BOOKING_REQUEST, handleGetDineinBooking),
]);
