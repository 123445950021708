import { ADD_CATEGORY_REQUEST, ADD_CHEF_REQUEST, ADD_DRIVER_REQUEST, ADD_KITCHEN_REQUEST, GET_CHEFS_LIST_REQUEST, GET_CHEF_MENU_REQUEST, GET_DELIVERY_AGENT_REQUEST, GET_DINEIN_BOOKING_REQUEST, GET_KITCHENS_LIST_REQUEST, GET_ORDERS_REQUEST, GET_USER_INFO_REQUEST, POST_LIVE_LOCATION_REQUEST, SEND_OTP_REQUEST, UPDATE_CHEFS_DETAIL_REQUEST, UPDATE_DELIVERY_AGENT_REQUEST, UPDATE_DISH_REQUEST, UPDATE_KITCHEN_REQUEST, UPDATE_ORDERS_REQUEST } from "../reducers/OrderReducer";

export const getOrders = (payload) => ({ type: GET_ORDERS_REQUEST, payload });

export const sendOtp = (payload) => ({ type: SEND_OTP_REQUEST, payload });

export const addDriver = (payload) => ({ type: ADD_DRIVER_REQUEST, payload });

export const addKitchen = (payload) => ({ type: ADD_KITCHEN_REQUEST, payload });

export const addChef = (payload) => ({ type: ADD_CHEF_REQUEST, payload });

export const addCategoryMenu = (payload) => ({ type: ADD_CATEGORY_REQUEST, payload });

export const updateOrders = (payload) => ({ type: UPDATE_ORDERS_REQUEST, payload });

export const getUserInfo = (payload) => ({ type: GET_USER_INFO_REQUEST, payload });

export const getDeliveryAgents = (payload) => ({ type: GET_DELIVERY_AGENT_REQUEST, payload });

export const getKitchensList = (payload) => ({ type: GET_KITCHENS_LIST_REQUEST, payload });

export const getChefMenuList = (payload) => ({ type: GET_CHEF_MENU_REQUEST, payload });

export const updateDisbDetails = (payload) => ({ type: UPDATE_DISH_REQUEST, payload });

export const getChefsList = (payload) => ({ type: GET_CHEFS_LIST_REQUEST, payload });

export const updateChefStatus = (payload) => ({ type: UPDATE_CHEFS_DETAIL_REQUEST, payload });

export const updateDeliveryAgents = (payload) => ({ type: UPDATE_DELIVERY_AGENT_REQUEST, payload });

export const updateKitchen = (payload) => ({ type: UPDATE_KITCHEN_REQUEST, payload });

export const postLiveLocation = (payload) => ({ type: POST_LIVE_LOCATION_REQUEST, payload });

export const getDineinBooking = (payload) => ({ type: GET_DINEIN_BOOKING_REQUEST, payload });
