import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDineinBooking } from "../../redux/actions/OrderAction";
import { formatDateTime } from "../../utils/helper";
import "./dineInBooking.css";

const DineInBooking = () => {
  const dispatch = useDispatch();
  const kitchenId = localStorage.getItem("userId");
  const kitchensList = useSelector((state) => state.orders.kitchensList.data);
  const dineinBooking = useSelector((state) => state.orders.dineinBooking.data);
  const kitchenInfo = kitchensList.find((k) => k._id === kitchenId);

  useEffect(() => {
    dispatch(getDineinBooking({ kitchenId }));
  }, []);

  return (
    <div style={{ height: "100vh", background: "#fffcf6" }}>
      <div style={{ padding: 20 }}>
        {dineinBooking.map((dinein) => (
          <div className="order-card Satoshi">
            <div className="order-card-header">
              <div className="order-card-header-user">
                <div className="order-card-user-details">
                  <div className="order-card-user-name Satoshi">
                    {kitchenInfo?.name}
                  </div>
                  <div className="order-card-user-location Satoshi">
                    {kitchenInfo?.address.slice(0, 40) + "..."}
                  </div>
                </div>
              </div>
              <button className="order-card-view-menu Satoshi transparentBtn">
                View menu
              </button>
            </div>

            <div style={{ marginBottom: 0 }} className="order-card-info">
              <div>
                <div className="orderCardDateWrapper">
                  <div
                    className="order-card-date Satoshi"
                    style={{ fontWeight: 500 }}
                  >
                    Name:
                  </div>{" "}
                  <div className="order-card-date Satoshi">
                    {dinein?.userInfo?.name}
                  </div>
                </div>
                <div className="orderCardDateWrapper">
                  <div
                    className="order-card-date Satoshi"
                    style={{ fontWeight: 500 }}
                  >
                    Guest:
                  </div>{" "}
                  <div className="order-card-date Satoshi">
                    {dinein.numberOfPeople}
                  </div>
                </div>
              </div>
            </div>
            <div className="order-card-info">
              <div className="orderCardDateWrapper">
                <div className="order-card-date Satoshi" style={{ fontWeight: 500 }} >Booking time:</div>
                <div className="order-card-date Satoshi">
                  {formatDateTime(dinein.day, dinein.time)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DineInBooking;
