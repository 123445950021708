import moment from "moment";

export const sortByDateOrder = (orders = []) => {
  const convertedData = orders.map((item) => ({
    ...item,
    createdAt: moment(item.createdAt),
  }));

  convertedData.sort((a, b) => (b.createdAt.isBefore(a.createdAt) ? -1 : 1));

  const groupedData = {};
  for (const item of convertedData) {
    const date = item.createdAt.format("DD MMM YYYY");
    if (!groupedData[date]) {
      groupedData[date] = [];
    }
    groupedData[date].push(item);
  }

  return Object.keys(groupedData).map((date) => ({
    date,
    dayOrder: groupedData[date],
  }));
};

export const isDriverUser = () =>{
  return +localStorage.getItem("userType") === 2;
}

export const removeIds = (arr) => {
  arr.forEach(obj => {
      delete obj.id; // Remove id from the object
      if (obj.options) {
          obj.options.forEach(option => {
              delete option.id; // Remove id from the option
          });
      }
  });
  return arr;
}

export const giveNextStatus = (status) => {
  switch (status) {
    case "CREATED":
      return "CONFIRMED";
    case "CONFIRMED":
      return "READY";
    case "READY":
      return "PICKED_UP";
    case "PICKED_UP":
      return "DELIVERED";
    default:
      break;
  }
};

export const convertTimeFormat = (timeString) => {
  // Split the time string into hours and minutes
  var timeArray = timeString.split(':');
  var hours = parseInt(timeArray[0]);
  var minutes = parseInt(timeArray[1]);

  // Convert hours to 12-hour format
  var meridiem = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  // Format the time string
  var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + meridiem;
  return formattedTime;
}

export const formatDateTime = (day, time) => {
  const dateTimeString = `${day.split('T')[0]}T${time}:00.000Z`;

  const dateTime = moment(dateTimeString);

  return dateTime.format('h:mm A, D MMM');
}
